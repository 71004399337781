import { PersoLists } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import type React from 'react';
import type { JSX } from 'react';
import IconClose from '../../../assets/svg/close.svg';
import { useTranslation } from '../../../lang';
import styles from './CardRemoveButton.css';

export type CardRemoveButtonProps = {
  contentID: string;
  listType?: PersoLists;
  hasSticker?: boolean;
  onClickRemove?: (
    e: React.MouseEvent,
    contentID: string,
    listType: PersoLists
  ) => void;
};

const cx = classNames.bind(styles);

/** Basic CardRemoveButton component used to remove card of your personal list */
export function CardRemoveButton({
  contentID,
  listType,
  onClickRemove,
  hasSticker,
}: CardRemoveButtonProps): JSX.Element {
  const { t } = useTranslation();
  const title =
    listType === PersoLists.Recommendations
      ? t('Card.notInterested')
      : t('Card.removeFromList');

  const handleClick = (e: React.MouseEvent) => {
    if (listType) {
      onClickRemove?.(e, contentID, listType);
    }
  };

  return (
    <button
      className={cx(styles.cardRemoveButton, {
        [styles['cardRemoveButton--hasSticker']!]: hasSticker,
      })}
      onClick={handleClick}
      type="button"
      title={title}
    >
      <IconClose title={title} />
    </button>
  );
}
